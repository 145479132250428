@use "../../globals" as *;
@use "../../variables" as *;
@use "../../mixins" as *;

.wrapper {
    overflow: hidden;

    .heading-container {
        width: 100%;
        text-align: left;
        padding: 1.25rem 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 1.25rem;
        background: transparent;
        border: none;
        cursor: pointer;
    }

    .arrow {
        font-size: 3rem;
        transition: 0.5s ease-in-out;
        color: $white;
        &.active {
            rotate: 180deg;
        }
    }

    .content-container {
        transition: all 1s ease-in-out;
        .team-container {
            display: flex;
            flex-direction: column;
            padding-top: 1.25rem;
            // padding-left: 10px;
            @include breakpoint-up(large) {
                flex-direction: row;
                align-content: flex-start;
            }
        }

        .headshot {
            display: inline;
            width: 100%;
            border-radius: 0.1875rem;
            height: auto;
            @include breakpoint-up(medium) {
                width: 14.6875rem;
                height: 19.6875rem;
            }
        }

        .team-heading {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: stretch;
            // transform: translate(0px, -10px);

            .name {
                line-height: 1.875rem;
            }
            // .title {
            //     line-height: 10px;
            // }

            p {
                // justify-content: space-between;
                text-align: left;
                // line-height: 40px;
                @include breakpoint-up(large) {
                    padding-left: 0.625rem;
                }
            }
        }

        .heading-container {
            display: flex;
        }
    }
}
