@use "../../globals" as *;
@use "../../variables" as *;
@use "../../mixins" as *;

.collage-card {
    background-color: #f9f9f9;
    background-size: cover;
    height: 50vh;
    margin: 0;
    padding: 0;
    box-shadow: inset 0 0 0 100vmax rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    flex-direction: column;
    background-position: 53% 47%;
    justify-content: center;

    .collection-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
