@use "../../globals" as *;
@use "../../variables" as *;
@use "../../mixins" as *;

.headshot {
    max-width: 50%;
    max-height: 100%;
}

.team-bio {
    // height: 90px;
    transition: height 0.7s ease-in-out;
    overflow: hidden;
    // height: inherit;

    .bio-text {
        padding-top: 0px;
        overflow: hidden;
        // height: inherit;
        line-height: 2.5rem;
        position: relative;
        word-wrap: break-word;

        // &::after {
        //     content: "...";
        //     color: $primaryColor;
        //     position: absolute;
        //     right: 0px;
        //     bottom: 2px;
        // }
    }
}

.work-container {
    // height: inherit;
    // overflow: hidden;
    h2 {
        color: $primaryColor;
        padding-top: 1.25rem;
    }

    p {
        padding-top: 0.625rem;
    }
    .work {
        &:last-of-type {
            padding-bottom: 5rem;
        }
    }
    display: flex;
    padding-top: 0.625rem;
    width: 100%;
    text-align: left;
    flex-direction: column;
    justify-content: space-evenly;
    @include breakpoint-up(large) {
        // flex-direction: row;
    }
}
