@use "../../globals" as *;
@use "../../variables" as *;
@use "../../mixins.scss" as *;

.hamburger {
    position: fixed;
    top: 1.0625rem;
    right: 1.0625rem;
    cursor: pointer;
    z-index: 1000;

    &:hover {
        opacity: 80%;
    }

    .line {
        width: 1.875rem;
        height: 0.3125rem;
        // background-color: $primaryColor;
        margin: 0.3125rem 0rem;
        border-radius: 0.1875rem;
        transition: all 250ms ease-in-out;
        &.active:nth-of-type(1) {
            // transition: all 100ms ease-in-out;
            transform: rotate(45deg);
            transform-origin: top left;
            // width: 28px;
        }

        &.active:nth-of-type(2) {
            // transition: all 100ms ease-in-out;
            transform-origin: center;
            width: 0;
        }

        &.active:nth-of-type(3) {
            // transition: all 100ms ease-in-out;
            transform: rotate(-45deg);
            transform-origin: bottom left;
            // width: 28px;
        }
    }
}
.small-logo {
    position: fixed;
    top: 1.0625rem;
    left: 0.9375rem;
    z-index: 1001; /* Ensure it's above the overlay and homebar */

    img {
        &:hover {
            opacity: 85%;
        }
        width: 2.5rem; /* Adjust the size as needed */
        height: 2.5rem;
        @include breakpoint-down("small") {
            width: 2.1875rem;
            height: 2.1875rem;
        }
        border-radius: 0.1875rem;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 998; /* Below the homebar */
    /* display: none; Initially hidden */
}

.homebar {
    position: fixed;
    top: 0;
    padding: {
        top: 2em;
        left: 2em;
    }
    width: 21.875rem;
    @include breakpoint-down(small) {
        width: 100vw;
    }
    height: 100dvh;
    background-color: #b8aca1;
    transition: right 0.3s ease-in-out;
    z-index: 999;

    ul {
        display: flex;
        flex-direction: column;
        align-items: left;
        padding-top: 2em;
        justify-content: space-between;
        height: 95%;
        list-style-type: none;

        @include breakpoint-down(medium) {
            padding-left: 0.625rem;
        }
        /* padding-bottom: 2em; */

        li {
            // margin-bottom: 50px;
            /* padding-left: 10px; */

            a {
                color: #ffffff;
                text-decoration: none;
                // font-size: 1.2em;
            }
        }
    }

    /* Scale the logo to fit within the homebar */
    .homebar-logo {
        max-width: 70%; /* Adjust the value as needed */
        width: 11.25rem;
        height: auto;
        transform: translate(0px, -2.8125rem);
        margin-bottom: 20px; /* Optional: Add margin to the bottom of the logo */
        &:hover {
            opacity: 80%;
        }
    }
}

.navLinks {
    li {
        a {
            &:hover {
                opacity: 50%;
            }
        }
        margin-bottom: 50px;
    }
    // border: solid red 2px;
    padding-bottom: 20px;
    font-size: 1.2em;
}

.footerLinks {
    li {
        margin-bottom: 1.875rem;
        cursor: default;

        a {
            &:hover {
                opacity: 50%;
            }
        }
    }

    .houzzIcon {
        width: 2.0844rem;
        height: 2.0844rem;
        transform: translate(0px, -0.1875rem);
        padding-left: 2px;
    }
    display: flex;
    flex-direction: column;
    justify-content: end;
    // border: solid red 2px;
    // position: absolute;
    // padding-bottom: 0px;
    // align-self: baseline;
    // align-content: flex-end;
    // line-height: 0px;
}

.social-icon {
    font-size: 2.5rem;
}
