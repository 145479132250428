@use "src/mixins.scss" as *;

@font-face {
    font-family: arialRoundedBold;
    src: url("./Fonts/ARLRDBD.TTF");
}

@font-face {
    font-family: arialNarrow;
    src: url("./Fonts/ARIALN.TTF");
}

html {
    font-size: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
}

body,
h1,
h2,
h3,
p,
ul,
li {
    margin: 0;
    padding: 0;
}

/* Set full-screen background image */
body {
    /* -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%; */
    font-family: arialRoundedBold, arialNarrow;

    color: #ffffff;
}

img {
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.hide-for-mobile {
    @include breakpoint-down(medium) {
        display: none;
    }
}

.hide-for-desktop {
    @include breakpoint-up(large) {
        display: none;
    }
}
