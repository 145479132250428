@use "../../globals" as *;
@use "../../variables" as *;
@use "../../mixins" as *;

.collage-display {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    background-color: $secondaryColor;
    @include breakpoint-down(medium) {
        grid-template-columns: 1fr;
    }

    @include breakpoint-up(xlarge) {
        grid-template-columns: repeat(3, 1fr);
    }
}
