@use "../../variables" as *;
@use "../../globals" as *;
@use "../../mixins" as *;

.mySwiper {
    width: 100%;
    height: 100%;
    padding-top: 4.375rem;
    padding-bottom: 2.5rem;
    background-color: $secondaryColor;
}

.centered-slide {
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 62.5rem;
    height: 85vh;
    // width: 100%;
    @include breakpoint-down(medium) {
        width: 21.875rem;
        height: 25rem;
    }

    overflow: hidden;
}

.centered-slide img {
    // display: block;
    right: 50;
    justify-self: center;
    // width: 1000px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 0.3125rem;
}

.swiper-pagination-bullet {
    background-color: $white;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    background-color: $primaryColor;
}

.swiper-button-prev {
    color: $white;
    z-index: 3;
    transform: translate(6.25rem, 0px);
    @include breakpoint-down(medium) {
        display: none;
    }

    &:hover {
        opacity: 50%;
    }
}

.swiper-button-next {
    color: $white;
    transform: translate(-6.25rem, 0px);
    position: absolute;
    z-index: 5;
    &:hover {
        opacity: 50%;
    }
    @include breakpoint-down(medium) {
        display: none;
    }
}
