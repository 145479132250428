@use "../../globals" as *;
@use "../../variables" as *;
@use "../../mixins" as *;

.about-container {
    width: 100%;
    min-height: 100vh;
    max-height: 100%;
    background-color: $secondaryColor;
    display: flex;
    flex-direction: row;
    // padding-top: 50px;
    align-items: flex-start;
    justify-content: space-around;

    .section {
        transition: all;
        margin-top: 3.125rem;
        @include breakpoint-up(large) {
            width: 75%;
        }
        h1 {
            padding-top: 0.625rem;
            color: $primaryColor;
            margin-bottom: 1.25rem;
            font-size: 2.25rem;
        }
        h2 {
            padding-top: 0.625rem;
            color: $primaryColor;
            margin-bottom: 1.25rem;
            font-size: 1.75rem;
        }

        p {
            color: $white;
            line-height: 1.875rem;
            font-size: 1.25rem;
        }

        .dropdown-content {
            max-height: 0;
        }
    }

    .about-text {
        max-width: 75%;
        @include breakpoint-up(large) {
            max-width: 55%;
            padding-right: 1.875rem;
            // margin: 0px auto;
            // margin-left: 300px;
        }
        height: 100%;
    }

    .about-images {
        display: flex;
        position: fixed;
        right: 0;
        width: 30%;
        height: 100vh;
        padding: 0rem;
        margin: 0rem;
        justify-content: end;
        img {
            justify-self: end;
            max-width: 23.125rem;
        }
    }
}

.project-team {
    h1 {
        color: $primaryColor;
    }
    background-color: $secondaryColor;
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    text-align: center;
}
