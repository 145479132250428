@use "../../globals" as *;
@use "../../variables" as *;
@use "../../mixins" as *;

.description {
    padding: 0rem;
    max-width: 100vw;
    text-align: center;
    color: $secondaryColor;
    display: block;

    .container {
        display: flex;
        justify-content: center;
        padding: 0;
        max-height: 50%;
        // max-width: 50vw;
        background-color: $secondaryColor;
        margin: 0 auto; /* Center the container horizontally */
    }

    .body-container {
        display: flex;
        max-width: 50%;
        @include breakpoint-down(small) {
            max-width: 70%;
        }
        flex-direction: column;
        // align-items: center;
        justify-content: center;
        padding: 0.625rem 0.625rem;
        @include breakpoint-up(large) {
            padding: 1.875rem 1.25rem;
        }
    }

    .title {
        font-size: 2.25rem;
        margin-bottom: 1.125rem;
        // color: #fdb715;
        color: $primaryColor;
    }

    .description_subheading {
        font-size: 0.875rem;
        margin-bottom: 1.125rem;
        color: $white;
        text-align: left;
        @include breakpoint-up(medium) {
            font-size: 0.875rem;
            margin-bottom: 1.125rem;
            text-align: center;
        }
    }

    .body-text {
        // font-family: Arial, Narrow;
        font-size: 1rem;
        line-height: 1.45rem;
        // white-space: pre;
        text-align: left;
        @include breakpoint-up(medium) {
            line-height: 1.875rem;
        }
        // margin-bottom: 1.875rem;
        color: $white;
    }

    .credit-text {
        font-size: 1rem;
        line-height: 1.45rem;
        text-align: left;
        @include breakpoint-up(medium) {
            // font-size: 16px;
            line-height: 1.875rem;
            // text-align: center;
        }
        color: $white;
    }
}
