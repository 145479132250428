.swiper {
    height: 100vh;
    width: 100vw;
    background-size: cover;
}

.landing-slide {
    width: 100%;
    height: 100%;
    position: relative;
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
