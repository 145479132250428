@media (min-width: 64em) {
    // styles go here
}

//640px, 1081px, 1400px
$breakpoints-up: (
    "medium": "40em",
    "large": "67.5626rem",
    "xlarge": "87.5em"
);

//639px, 1080px, 1399px
$breakpoints-down: (
    "small": "39.9375em",
    "medium": "67.5em",
    "large": "87.4375em"
);

@mixin breakpoint-up($size) {
    @media (min-width: map-get($breakpoints-up, $size)) {
        @content;
    }
}
@mixin breakpoint-down($size) {
    @media (max-width: map-get($breakpoints-down, $size)) {
        @content;
    }
}
