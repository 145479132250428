@use "../../globals" as *;
@use "../../mixins.scss" as *;
@use "../../variables" as *;

.project-page-container {
    height: 100vh;
    background-color: $secondaryColor;
}

.scroll-container {
    width: 100vw;
    // min-height: 100vh;
    background-color: $secondaryColor;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    .scroll-link {
        margin-top: 0rem;
        cursor: pointer;
        // width: 100vw;
        // background-color: $secondaryColor;
        color: $white;
        font-size: 18px;
        transform: translateX(-1.0625rem);
        // align-self: center;
        text-decoration: none;
        &:hover {
            color: #ff9800;
        }
    }

    p {
        margin-top: 0rem;
        padding-bottom: 0rem;
        cursor: pointer;
        color: $white;
        font-size: 1rem;
        text-decoration: none;
        &:hover {
            color: $primaryColor;
        }
    }
}
