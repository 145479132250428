@use "../../globals" as *;
@use "../../variables" as *;
@use "../../mixins" as *;

.contact-page {
    background-color: $secondaryColor;
    background-size: cover;
    background-image: url("../../Graphics/Contact-Page.webp");
    @include breakpoint-down("small") {
        background-position: 20% 80%;
    }
    width: 100vw;
    height: 100vh;
    // padding: 40px 40px 40px 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    color: white;

    .contact-container {
        display: flex;
        flex-direction: column;
        width: 60%;
        justify-content: space-between;
        padding: 3.125rem 4.375rem;
        margin: 1.25rem auto;

        @include breakpoint-down("small") {
            // transform: translate(0px, -50px);
        }

        h1 {
            padding: 2.1875rem 0rem;
        }

        p {
            padding: 0.3125rem 0rem;
            font-size: 1.25rem;
            @include breakpoint-down("small") {
                font-size: 1rem;
            }
        }

        a {
            width: fit-content;
            text-decoration: none;
            color: white;
            padding: 0.3125rem 0rem;
            font-size: 1.25rem;

            @include breakpoint-down("small") {
                font-size: 1rem;
            }

            &:hover {
                color: $primaryColor;
            }
        }
    }
}
