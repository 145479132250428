@use "../../globals" as *;
@use "../../mixins.scss" as *;
@use "../../variables" as *;

.scroll-snap {
    scroll-snap-type: y proximity;

    div {
        scroll-snap-align: center;
    }
}

.main-content {
    height: 100vh;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    .landing-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.25); /* Semi-transparent overlay */
        z-index: 2;
    }

    .hero-content {
        align-content: center;
        cursor: default;

        .landing-logo {
            // left: 35vw;
            position: absolute;
            width: 37.5rem;
            height: auto;
            // margin-left: auto;
            // margin-right: auto;
            top: 50%; /* position the top  edge of the element at the middle of the parent */
            left: 50%; /* position the left edge of the element at the middle of the parent */

            transform: translate(-50%, -50%);
            text-align: center;
            z-index: 20;

            @include breakpoint-down(small) {
                width: 18.75rem;
                height: auto;
            }
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

.cta-info {
    height: 130vh;
    width: 100vw;
    background-position: 35% 65%;

    .cta-content {
        background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
        display: flex;
        position: absolute;
        flex-direction: column;
        align-content: center;
        overflow: hidden;
        // left: 50;
        justify-content: center;
        // max-width: 100%;
        // @include breakpoint-up(large) {
        //     max-width: 100%;
        // }
        // position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        height: 100vh;

        a {
            text-decoration: none;
            color: $white;

            &:hover {
                color: $primaryColor;
            }
        }
    }

    .cta {
        font-size: 1.125rem;
        line-height: 2.5rem;
        margin: 1.125rem;

        @include breakpoint-up(medium) {
            font-size: 1.25rem;
            line-height: 3.4375rem;
        }

        @include breakpoint-up(large) {
            margin: 6.25rem;
            font-size: 1.875rem;
            line-height: 4.6875rem;
        }

        @include breakpoint-up(xlarge) {
            margin: 9.375rem;
            font-size: 2.1875rem;
            line-height: 4.375rem;
        }

        // @media (max-width: 1080px) and (max-height: 420px) {
        //     font-size: 1.375rem;
        //     line-height: 2.1875rem;
        // }

        @media (max-width: 768px), (max-height: 768px) {
            font-size: 1.25rem;
            line-height: 2.125rem;
        }

        // @include breakpoint-up(medium) {
        //     font-size: 1.25rem;
        //     line-height: 3.4375rem;
        //     margin: 2.8125rem;
        // }
    }
}

.project-display {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    background-color: $secondaryColor;
    // aspect-ratio: 16/9;
    width: 100%;
    height: auto;
    @include breakpoint-down(medium) {
        grid-template-columns: 1fr;
        aspect-ratio: 12/6;
    }

    @include breakpoint-up(xlarge) {
        grid-template-columns: repeat(3, 1fr);
    }
    text-align: center;
}
