@use "../../globals" as *;
@use "../../mixins.scss" as *;
@use "../../variables" as *;

#Woodlawn\ II {
    background-position: top;
}

#Maria\'s {
    background-position: bottom;
}

#Lake\ Meadows {
    background-position: 20% 80%;
}

.project-card {
    background-color: #f9f9f9;
    background-size: cover;
    width: 100%;
    height: auto;
    aspect-ratio: 16/12;
    margin: 0;
    padding: 0;
    box-shadow: inset 0 0 0 100vmax rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    flex-direction: column;
    background-position: 53% 47%;
    justify-content: center;

    .card-text {
        display: none;
    }

    //TODO: Need to figure out hover effect with title,
    // .card-image {
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    // }

    &:hover {
        opacity: 85%;
        .card-text {
            display: block;
            color: $white;
        }
    }
}
