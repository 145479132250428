@use "../../globals" as *;
@use "../../mixins.scss" as *;
@use "../../variables" as *;

.collection-card {
    background-color: #f9f9f9;
    background-size: cover;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    box-shadow: inset 0 0 0 100vmax rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    flex-direction: column;
    background-position: 53% 47%;
    justify-content: center;

    .card-text {
        display: none;
    }

    &:hover {
        opacity: 85%;
        .card-text {
            display: block;
            color: $white;
        }
    }
}
